import { FaqForm } from '.';
import { apiSlice } from '../../app/api/apiSlice';
import { CategoryFaq } from '../../types/faq/faq';
import { getRegistrationToken } from '../../utils/getRegistrationToken';

export const faqApiSlice = apiSlice.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    getCategory: query<CategoryFaq[], null>({
      query: () => ({
        url: `database/contact_category__c`,
        method: 'GET',
        headers: {
          Authorization: getRegistrationToken(),
        },
      }),
    }),
    sendContact: mutation<FaqForm, { item: FaqForm; isAuth: boolean }>({
      query: arg => ({
        url: `database/contact__c`,
        method: 'post',
        ...(!arg.isAuth && {
          headers: {
            Authorization: getRegistrationToken('multiclientes'),
          },
        }),
        body: arg.item,
      }),
    }),
  }),
});

export const { useGetCategoryQuery, useSendContactMutation } = faqApiSlice;
