import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as z from 'zod';

import { Button } from '../../components/Button';
import { inputClass, messageError } from '../../styles';
import { selectUser } from '../auth/authSlice';
import { useGetCategoryQuery, useSendContactMutation } from './faqApiSlice';
import { RequirementsSchemaFaq } from './utils/typeForm';

export type FaqForm = z.infer<typeof RequirementsSchemaFaq>;

export function Faq() {
  const user = useSelector(selectUser);
  const { data } = useGetCategoryQuery(null);
  const [sendContact] = useSendContactMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors, isSubmitSuccessful },
  } = useForm<FaqForm>({
    mode: 'all',
    resolver: zodResolver(RequirementsSchemaFaq),
  });

  async function handleForm(item: FaqForm) {
    const payload = JSON.parse(JSON.stringify(item));

    payload.status = 'aberto';
    payload.created = { $date: new Date().getTime() };

    try {
      await sendContact({
        item: payload,
        isAuth: !!user,
      });

      toast.success('Mensagem enviada com sucesso!', {
        position: 'top-right',
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error(error);

      toast.error('Erro ao enviar sucesso!', {
        position: 'top-right',
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }

  useEffect(() => {
    reset({
      subject: '',
      category: '',
      email: '',
      message: '',
      name: '',
    });
  }, [isSubmitSuccessful, reset]);

  useEffect(() => {
    reset({
      email: user?.email || '',
      name: user?.name || '',
    });
  }, [user, reset]);

  return (
    <div className="container mx-auto pt-10 mb-5">
      <form className="space-y-5" onSubmit={handleSubmit(handleForm)}>
        <div>
          <label htmlFor="name">Nome</label>
          <input
            className={inputClass}
            id="name"
            type="text"
            {...register('name')}
          />
          <span className={messageError}>{errors?.name?.message}</span>
        </div>

        <div>
          <label htmlFor="email">Email</label>
          <input
            className={inputClass}
            id="email"
            type="text"
            {...register('email')}
          />
          <span className={messageError}>{errors?.email?.message}</span>
        </div>

        <div>
          <label htmlFor="state">Categoria</label>
          <select className={inputClass} id="state" {...register('category')}>
            <option value="">Clique para selecionar</option>
            {data?.map(item => (
              <option key={item._id} value={item._id}>
                {item.title}
              </option>
            ))}
          </select>

          <span className={messageError}>{errors?.category?.message}</span>
        </div>

        <div>
          <label htmlFor="subject">Assunto</label>
          <input
            className={inputClass}
            id="subject"
            type="text"
            {...register('subject')}
          />
          <span className={messageError}>{errors?.subject?.message}</span>
        </div>

        <div>
          <label htmlFor="message">Mensagem</label>
          <textarea
            className={inputClass}
            id="message"
            {...register('message')}
          />
          <span className={messageError}>{errors?.message?.message}</span>
        </div>

        <div className="w-fit">
          <Button disabled={isSubmitting} loading={isSubmitting} type="submit">
            Enviar
          </Button>
        </div>
      </form>
    </div>
  );
}
